<template>
  <div>
    <Container title="Banners no checkout">
      <template v-slot:content>
        <hs-group>
          <MSwitch
            v-model="enableTopBanner"
            label-title="Habilitar banner topo"
            label-description="Adicione um banner customizado no topo do seu checkout"
          />
        </hs-group>

        <div class="banner-config__content tlw-mb-4" v-if="enableTopBanner">
          <InputImage
            title="Computador"
            required
            :schoolId="schoolId"
            :aspectRatio="'910 / 300'"
            v-model="topBannerDesktopHandler"
          />
          <InputImage
            title="Celular"
            :schoolId="schoolId"
            :aspectRatio="'320 / 220'"
            v-model="topBannerMobileHandler"
          />
        </div>

        <div class="header-lateal-banner tlw-flex tlw-justify-between tlw-items-center">
          <hs-group class="group">
            <MSwitch
              v-model="enableSideBanner"
              label-title="Habilitar banner lateral"
              label-description="Adicione um banner customizado na lateral do seu checkout"
            />
          </hs-group>
          <div v-if="enableSideBanner" class="tabs tlw-mb-2">
            <input type="radio" value="third" id="radio-1" name="tabs" v-model="selectedBannerOption" checked />
            <label class="tab" for="radio-1">3 banners</label>
            <input type="radio" value="second" id="radio-2" name="tabs" v-model="selectedBannerOption" />
            <label class="tab" for="radio-2">2 banners</label>
            <input type="radio" value="first" id="radio-3" name="tabs" v-model="selectedBannerOption" />
            <label class="tab" for="radio-3">1 banner</label>
            <span class="glider"></span>
          </div>
        </div>
        <div v-if="enableSideBanner" class="lateral-banner__content tlw-mt-2">
          <div v-for="banner in renderSideBanner" :key="banner.id">
            <InputImage
              :schoolId="schoolId"
              :aspectRatio="aspectRatio"
              hiddenDescription
              :value="banner.image"
              @input="setSideBanner(banner.id, $event)"
            />
          </div>
        </div>
        <p class="lateral-banner__text tlw-mt-4" v-if="enableSideBanner">
          As dimensões ideais: <strong>{{ formattedAspectRatio }}</strong> pixels no formato JPG ou PNG e ter no máximo
          <strong>5 MB</strong> de tamanho.
        </p>
      </template>
    </Container>
  </div>
</template>

<script>
import Container from './Container.vue';
import InputImage from '../InputImage.vue';
import MSwitch from '@/shared/components/MSwitch.vue';
import { mapState, mapActions, mapGetters } from 'vuex';

export default {
  name: 'BannersConfig',
  components: {
    Container,
    InputImage,
    MSwitch,
  },
  props: {
    schoolId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      selectedBannerOption: 'third',
    };
  },
  methods: {
    ...mapActions('offerWizard', [
      'setIsTopBannerEnabled',
      'setIsSideBannerEnabled',
      'setTopBannerSettings',
      'setSideBannerSettings',
      'setTopBannerSettings',
    ]),
    setSideBanner(id, value) {
      this.setSideBannerSettings({
        ...this.sideBannerSettings,
        [id]: value,
      });
    },
    setTopBannerDesktop(bannerUrl) {
      this.setTopBannerSettings({
        ...this.topBannerSettings,
        desktop: bannerUrl,
      });
    },
    setTopBannerMobile(bannerUrl) {
      this.setTopBannerSettings({
        ...this.topBannerSettings,
        mobile: bannerUrl,
      });
    },
  },
  mounted() {
    switch (this.amountOfUploadedBanners) {
      case 1:
        this.selectedBannerOption = 'first';
        return;
      case 2:
        this.selectedBannerOption = 'second';
        return;
      case 3:
        this.selectedBannerOption = 'third';
        return;
      default:
        this.selectedBannerOption = 'third';
        return;
    }
  },
  computed: {
    ...mapState('offerWizard', ['isTopBannerEnabled', 'isSideBannerEnabled']),
    ...mapGetters('offerWizard', ['topBannerSettings', 'sideBannerSettings']),
    aspectRatio() {
      const width = 259;
      let height;
      switch (this.amountBanners) {
        case 3:
          height = 180;
          break;
        case 2:
          height = 280;
          break;
        case 1:
          height = 580;
          break;
      }
      return `${width} / ${height}`;
    },
    formattedAspectRatio() {
      const [a, b] = this.aspectRatio.split('/');
      let finalString = `${a.replace(' ', '')}`;
      finalString += 'x';
      finalString += `${b.replace(' ', '')}`;
      return finalString;
    },
    renderSideBanner() {
      const amountBanners = this.amountBanners;
      return Object.entries(this.sideBannerSettings).reduce((acc, [key, value], index) => {
        if (index < amountBanners) {
          acc.push({
            id: key,
            image: value,
          });
        }
        return acc;
      }, []);
    },
    amountBanners() {
      switch (this.selectedBannerOption) {
        case 'first':
          return 1;
        case 'second':
          return 2;
        case 'third':
          return 3;
        default:
          return 3;
      }
    },
    amountOfUploadedBanners() {
      if (this.sideBannerSettings) return Object.values(this.sideBannerSettings).filter(banner => banner).length;
      return 0;
    },
    enableTopBanner: {
      get() {
        return this.isTopBannerEnabled;
      },
      set(value) {
        this.setIsTopBannerEnabled(value);
      },
    },
    enableSideBanner: {
      get() {
        return this.isSideBannerEnabled;
      },
      set(value) {
        this.setIsSideBannerEnabled(value);
      },
    },
    topBannerDesktopHandler: {
      get() {
        return this.topBannerSettings?.desktop;
      },
      set(value) {
        this.setTopBannerSettings({
          ...this.topBannerSettings,
          desktop: value,
        });
      },
    },
    topBannerMobileHandler: {
      get() {
        return this.topBannerSettings?.mobile;
      },
      set(value) {
        this.setTopBannerSettings({
          ...this.topBannerSettings,
          mobile: value,
        });
      },
    },
  },
  watch: {
    amountBanners() {
      if (this.amountBanners < 3) this.setSideBanner('third', null);
      if (this.amountBanners < 2) this.setSideBanner('second', null);
    },
  },
};
</script>

<style lang="scss" scoped>
.group {
  margin-bottom: 0;
}
.banner-config {
  &__content {
    display: grid;
    grid-template-columns: 1fr 0.5fr;
    gap: 16px;
    max-width: 806px;

    /deep/.banner {
      > div > div > p {
        font-size: 12px;
      }
    }
  }
}

.lateral-banner {
  &__content {
    display: flex;
    gap: 16px;
    max-width: 806px;

    > div {
      width: 100%;
      max-width: 268.6px;
    }
  }
}

input[type='radio'] {
  display: none;
}

.tabs {
  display: flex;
  position: relative;
  background-color: #e3e3e3;
  box-shadow: 0 0 1px 0 rgba(#185ee0, 0.15), 0 6px 12px 0 rgba(#185ee0, 0.15);
  padding: 2px;
  border-radius: 8px;
}

.tab {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  width: 100px;
  border-radius: 99px; // just a high number to create pill effect
  cursor: pointer;
  transition: color 0.15s ease-in;
  margin-bottom: 0;
  padding: 6px 16px;
  z-index: 2;
}

input[type='radio'] {
  &:checked {
    & + label {
      color: #262626;
      font-weight: bold;
    }
  }
}

input[id='radio-1'] {
  &:checked {
    & ~ .glider {
      transform: translateX(0);
    }
  }
}

input[id='radio-2'] {
  &:checked {
    & ~ .glider {
      transform: translateX(100%);
    }
  }
}

input[id='radio-3'] {
  &:checked {
    & ~ .glider {
      transform: translateX(200%);
    }
  }
}

.glider {
  position: absolute;
  display: flex;
  height: 31.2px;
  width: 100px;
  background-color: white;
  z-index: 1;
  border-radius: 8px; // just a high number to create pill effect
  transition: 0.25s ease-out;
}

.lateral-banner__text {
  color: #6f6f6f;
  font-size: 12px;
}

@media screen and (max-width: 1024px) {
  .lateral-banner__content {
    flex-wrap: wrap;
    max-width: 400px;
  }
  .banner-config__content {
    grid-template-columns: 1fr;

    .banner:last-child {
      max-width: 264px;
    }
  }
  .header-lateal-banner {
    flex-direction: column;
    align-items: start;
    gap: 24px;
  }
}
</style>
