<template>
  <div>
    <input type="text" v-model="currentValue" :maxlength="limit" :placeholder="placeholder" class="input" />
    <span class="count">{{ count }} / {{ limit }}</span>
  </div>
</template>

<script>
export default {
  name: 'Input',
  props: {
    value: {
      type: String,
      default: '',
    },
    limit: {
      type: Number,
      default: 50,
    },
    placeholder: {
      type: String,
      default: 'Digite aqui',
    },
  },
  data() {
    return {
      currentValue: this.value,
    };
  },
  computed: {
    count() {
      return this.currentValue.length;
    },
  },
  watch: {
    currentValue(newValue) {
      this.$emit('change', newValue);
    },
  },
  model: {
    prop: 'value',
    event: 'change',
  },
};
</script>

<style scoped>
.input {
  width: 100%;
  padding: 10px;
  border: 1px solid #bababa;
  border-radius: 5px;
  margin-bottom: 5px;
}
.count {
  color: #999;
  font-size: 12px;
}
</style>
