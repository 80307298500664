<template>
  <div class="offer-step-appearence">
    <header>
      <h3>Checkout</h3>
      <span>(Opcional)</span>
    </header>

    <BannersConfig :schoolId="schoolId" />

    <hr class="separator" />

    <BackgroundConfig :schoolId="schoolId" />

    <hr class="separator" />

    <TimerConfig />

    <PersonalDataConfig />
  </div>
</template>

<script>
import TimerConfig from '../CheckoutConfig/TimerConfig.vue';
import BannersConfig from '../CheckoutConfig/BannersConfig.vue';
import BackgroundConfig from '../CheckoutConfig/BackgroundConfig.vue';
import PersonalDataConfig from '../CheckoutConfig/PersonalDataConfig.vue';

export default {
  name: 'StepTwo',
  components: {
    TimerConfig,
    BannersConfig,
    BackgroundConfig,
    PersonalDataConfig,
  },
  props: {
    schoolId: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.separator {
  margin: 24px 0;
}

.offer-step-appearence {
  header {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 30px;

    h3 {
      font-size: 20px;
      line-height: 25px;
      font-weight: 600;
      color: #262626;
    }

    span {
      font-size: 12px;
      line-height: 18px;
      font-weight: 400;
      color: #6f6f6f;
    }
  }
}
</style>
