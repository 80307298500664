<template>
  <Container title="Gatilhos de escassez">
    <template v-slot:content>
      <MSwitch
        v-model="enableTimer"
        label-title="Habilitar Cronômetro"
        label-description="Configure o gatilho de tempo no seu checkout"
        data-testid="enable-timer-switch"
      />

      <div class="timer" v-if="enableTimer" data-testid="timer-config-section">
        <h5>Texto do contador</h5>
        <InputCounter v-model="timerTextHandler" data-testid="text-timer-input" />

        <div class="timer-config">
          <div>
            <InputColor
              v-model="timerBackgroundColorHandler"
              label-text="Cor do background"
              data-testid="color-background-input"
            />

            <InputColor v-model="timerTextColorHandler" label-text="Cor do texto" data-testid="color-text-input" />
          </div>

          <TimeInput v-model="timerTimeHandler" data-testid="timer-input" />
        </div>
      </div>
    </template>
  </Container>
</template>

<script>
import Container from './Container.vue';
import MSwitch from '@/shared/components/MSwitch.vue';
import InputColor from '@/components/InputColor.vue';
import InputCounter from '../InputCounter.vue';
import TimeInput from './TimeInput.vue';
import { mapState, mapActions, mapGetters } from 'vuex';

export default {
  name: 'TimerConfig',
  components: {
    Container,
    MSwitch,
    InputColor,
    InputCounter,
    TimeInput,
  },
  computed: {
    ...mapState('offerWizard', ['isTimerEnabled']),
    ...mapGetters('offerWizard', ['timerSettings']),
    enableTimer: {
      get() {
        return this.isTimerEnabled;
      },
      set(value) {
        this.setIsTimerEnabled(value);
      },
    },
    timerTextHandler: {
      get() {
        return this.timerSettings?.text;
      },
      set(value) {
        this.setTimerSettings({
          ...this.timerSettings,
          text: value,
        });
      },
    },
    timerBackgroundColorHandler: {
      get() {
        return this.timerSettings?.background_color;
      },
      set(value) {
        this.setTimerSettings({
          ...this.timerSettings,
          background_color: value,
        });
      },
    },
    timerTextColorHandler: {
      get() {
        return this.timerSettings?.text_color;
      },
      set(value) {
        this.setTimerSettings({
          ...this.timerSettings,
          text_color: value,
        });
      },
    },
    timerTimeHandler: {
      get() {
        return this.timerSettings?.time;
      },
      set(value) {
        this.setTimerSettings({
          ...this.timerSettings,
          time: value,
        });
      },
    },
  },
  methods: {
    ...mapActions('offerWizard', ['setTimerSettings', 'setIsTimerEnabled']),
  },
};
</script>

<style lang="scss" scoped>
.timer {
  margin-top: 24px;
  max-width: 474px;

  h5 {
    font-size: 14px;
    margin-bottom: 8px;
  }
}

.timer-config {
  display: flex;
  gap: 16px;
  margin-top: 16px;

  /deep/#time-input {
    width: 100%;
  }

  > div {
    display: flex;
    gap: 16px;
  }
}

@media screen and (max-width: 1024px) {
  .timer-config {
    flex-wrap: wrap;

    > div {
      width: 100%;
      .timer-input,
      /deep/label > div {
        width: 100%;
        max-width: unset;
      }

      /deep/.timer-input label {
        min-width: unset;
      }
    }
  }
  .countdown-input {
    width: 100%;
    /deep/label {
      width: 100%;
    }
  }
}
</style>
