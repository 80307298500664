<template>
  <div class="countdown-input">
    <label for="time-input">
      Tempo:
      <div class="countdown-input__content">
        <div id="icon-container" class="d-flex justify-content-center align-items-center">
          <i class="fal fa-clock"></i>
        </div>

        <input
          id="time-input"
          v-mask="'##:##:##'"
          placeholder="HH:mm:ss"
          v-model="formattedTime"
          @input="updateTimeInSeconds"
        />
      </div>
    </label>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { mask } from 'vue-the-mask';

export default {
  directives: { mask },
  props: {
    value: Number,
  },
  data() {
    return {
      formattedTime: this.secondsToFormattedTime(this.value),
    };
  },
  methods: {
    updateTimeInSeconds() {
      const seconds = this.formattedTimeToSeconds(this.formattedTime);

      if (!seconds) return;

      this.$emit('input', seconds);
    },
    formattedTimeToSeconds(timeString) {
      const [hours, minutes, seconds] = timeString.split(':').map(Number);

      if (hours === undefined || minutes === undefined || seconds === undefined) return 0;

      return hours * 3600 + minutes * 60 + seconds;
    },
    secondsToFormattedTime(timeInSeconds) {
      return dayjs()
        .startOf('day')
        .add(timeInSeconds, 'second')
        .format('HH:mm:ss');
    },
  },
};
</script>

<style lang="scss" scoped>
.countdown-input {
  &__content {
    margin-top: 8px;
    display: flex;
    align-items: center;
    gap: 8px;

    border: 1px solid #bababa;
    border-radius: 4px;
    padding: 8px 16px;
    line-height: 18px;

    width: 100%;
    text-align: center;
  }

  input {
    &:focus {
      outline: none !important;
    }
  }

  input::-webkit-calendar-picker-indicator {
    display: none;
  }

  label {
    min-width: 148px;
  }
}
</style>
