<template>
  <div class="timer-input">
    <label>
      {{ labelText }}
      <div class="timer-input__content">
        <div class="timer-input__color" :style="`backgroundColor: ${value}`" />
        <input class="input-color-text" type="text" v-model="color" />
        <input class="input-color" type="color" v-model="color" />
      </div>
    </label>
  </div>
</template>

<script>
export default {
  name: 'InputColor',
  props: {
    value: String,
    labelText: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      color: this.value,
    };
  },
  watch: {
    color: function(val) {
      this.$emit('change', val);
    },
    value: function(val) {
      this.color = val;
    },
  },
  model: {
    prop: 'value',
    event: 'change',
  },
};
</script>

<style lang="scss" scoped>
.timer-input {
  display: flex;
  flex-direction: column;

  &__content {
    display: flex;
    align-items: center;
    gap: 8px;
    max-width: 148px;

    cursor: pointer;
    border: 1px solid #bababa;
    border-radius: 4px;
    padding: 8px 16px;
    margin-top: 8px;
  }

  &__color {
    width: 14px;
    height: 14px;
    border: 1px solid #bababa;
    border-radius: 2px;
  }

  label {
    min-width: 148px;
    .input-color {
      position: absolute;
      opacity: 0;
      max-width: 16px;
    }

    .input-color-text {
      width: 100%;
    }
  }
}
</style>
