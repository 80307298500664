<template>
  <div class="tlw-mt-6 tlw-border-t tlw-border-gray-100">
    <h4 class="tlw-mb-5 tlw-mt-4 tlw-font-bold tlw-text-base">
      {{ $t('sparkmembers.offers.offer-wizard.step-two.required-personal-information') }}
    </h4>
    <hs-group class="form tlw-mb-4">
      <MSwitch
        v-model="enabledWhatsapp"
        :label-title="$t('sparkmembers.offers.offer-wizard.step-two.whatsapp-toggle-title')"
        :label-description="$t('sparkmembers.offers.offer-wizard.step-two.whatsapp-toggle-description')"
        data-testid="enable-whatsapp-switch"
      />
    </hs-group>
    <div v-if="enabledWhatsapp" data-testid="whatsapp-config-section">
      <hs-group>
        <label for="whatsapp_number" class="font-weight-bold">{{
          $t('sparkmembers.offers.offer-wizard.step-two.whatsapp-widget-number')
        }}</label>
        <hs-input
          name="whatsapp_number"
          id="whatsapp_number"
          type="tel"
          v-model="whatsappNumberHandler"
          v-mask="['(##) ####-####', '(##) #####-####']"
          :placeholder="$t('sparkmembers.offers.offer-wizard.step-two.whatsapp-number-placeholder')"
          data-testid="whatsapp-number-input"
        />
      </hs-group>
      <hs-group>
        <label for="whatsapp_text" class="font-weight-bold">{{
          $t('sparkmembers.offers.offer-wizard.step-two.whatsapp-widget-text')
        }}</label>
        <hs-input
          name="whatsapp_text"
          id="whatsapp_text"
          type="text"
          v-model="whatsappTextHandler"
          :placeholder="$t('sparkmembers.offers.offer-wizard.step-two.whatsapp-text-placeholder')"
          data-testid="whatsapp-text-input"
        />
      </hs-group>
    </div>
    <hs-group class="tlw-mb-2">
      <MSwitch
        v-model="requiredPhoneHandler"
        :label-title="$t('sparkmembers.offers.offer-wizard.step-two.enable-required-phone-title')"
        :label-description="$t('sparkmembers.offers.offer-wizard.step-two.enable-required-phone-description')"
        data-testid="required-phone-switch"
      />

      <MSwitch
        v-if="displayRequireAddressFeature"
        v-model="requiredAddressHandler"
        :label-title="$t('sparkmembers.offers.offer-wizard.step-two.enable-required-address-title')"
        :label-description="$t('sparkmembers.offers.offer-wizard.step-two.enable-required-address-description')"
        class="tlw-mt-4"
        data-testid="required-address-switch"
      />
    </hs-group>
    <hs-group class="tlw-mb-2">
      <MSwitch
        v-model="emailConfirmationHandler"
        :label-title="$t('sparkmembers.offers.offer-wizard.step-two.enable-email-confirmation-title')"
        :label-description="$t('sparkmembers.offers.offer-wizard.step-two.enable-email-confirmation-description')"
        data-testid="email-confirmation-switch"
      />
    </hs-group>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { mask } from 'vue-the-mask';
import MSwitch from '@/shared/components/MSwitch.vue';

export default {
  name: 'PersonalDataConfig',
  components: {
    MSwitch,
  },
  directives: { mask },
  computed: {
    ...mapState('offerWizard', ['isWhatsappEnabled']),
    ...mapGetters('offerWizard', ['whatsappSettings', 'checkoutSettings']),
    displayRequireAddressFeature() {
      return this.$FCL.offerRequireAddress();
    },
    enabledWhatsapp: {
      get() {
        return this.isWhatsappEnabled;
      },
      set(value) {
        this.setIsWhatsappEnabled(value);
      },
    },
    whatsappNumberHandler: {
      get() {
        return this.whatsappSettings?.whatsapp_number;
      },
      set(value) {
        this.setWhatsappSettings({
          ...this.whatsappSettings,
          whatsapp_number: value,
        });
      },
    },
    whatsappTextHandler: {
      get() {
        return this.whatsappSettings?.whatsapp_text;
      },
      set(value) {
        this.setWhatsappSettings({
          ...this.whatsappSettings,
          whatsapp_text: value,
        });
      },
    },
    requiredPhoneHandler: {
      get() {
        return this.checkoutSettings?.require_phone;
      },
      set(value) {
        this.setRequirePhone(value);
      },
    },
    requiredAddressHandler: {
      get() {
        return this.checkoutSettings?.require_address;
      },
      set(value) {
        this.setRequireAddress(value);
      },
    },
    emailConfirmationHandler: {
      get() {
        return this.checkoutSettings?.email_confirmation;
      },
      set(value) {
        this.setEmailConfirmation(value);
      },
    },
  },
  methods: {
    ...mapActions('offerWizard', [
      'setWhatsappSettings',
      'setIsWhatsappEnabled',
      'setRequireAddress',
      'setRequirePhone',
      'setEmailConfirmation',
    ]),
  },
};
</script>

<style scoped lang="scss">
.form {
  margin-bottom: 16px;
}
</style>
