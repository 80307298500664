<template>
  <div class="banner">
    <div v-if="title" class="title">
      <h4>{{ title }}</h4>
      <span>{{ requiredText }}</span>
    </div>
    <div data-testid="banner-section">
      <div v-if="bannerUrl === null">
        <input
          type="file"
          @change="onFileInputChange"
          ref="file"
          accept="image/jpg, image/jpeg, image/png"
          style="display: none"
        />
        <div
          class="banner-img tlw-flex tlw-items-center tlw-justify-center tlw-border tlw-border-purple-500 tlw-border-dashed tlw-cursor-pointer"
          @click="$refs.file.click()"
          @dragover.prevent="dragLeaveOrOver"
          @dragleave.prevent="dragLeaveOrOver"
          @drop.prevent="drop($event)"
        >
          <div class="banner-img-empty">
            <div class="icon-helper tlw-text-center">
              <hs-icon class="tlw-text-gray-700" variant="regular" :size="20" icon="image" />
            </div>
            <p
              v-html="$t('sparkmembers.offers.offer-wizard.step-two.banner-drop-title')"
              class="drop-title tlw-text-gray-700"
            />
          </div>
        </div>
        <p v-if="!hiddenDescription" class="tlw-mt-3 tlw-font-normal tlw-text-sm tlw-text-gray-700">
          As dimensões ideais: <strong>{{ formatedAspectRatio }}</strong> pixels no formato JPG ou PNG e ter no máximo
          <strong>5 MB</strong> de tamanho.
        </p>
      </div>
      <div v-else>
        <img :src="bannerUrl" alt="" />
        <button
          class="tlw-text-red-600 tlw-underline tlw-font-normal tlw-mt-2 tlw-block tlw-cursor-pointer"
          type="button"
          @click="bannerUrl = null"
        >
          {{ $t('sparkmembers.offers.offer-wizard.step-two.remove-image') }}
        </button>
      </div>
      <CropImage
        v-if="uploadImg != null"
        :aspect-ratio="aspectRatioNumber"
        :src="uploadImg"
        :is-saving="isSavingCropImage"
        @close="uploadImg = null"
        @save="onSaveCropImg"
      />
    </div>
  </div>
</template>

<script>
import CropImage from '@/components/CropImage.vue';
import uploadService from '@/services/upload';

export default {
  name: 'InputImage',
  components: {
    CropImage,
  },
  props: {
    schoolId: {
      type: Number,
      required: true,
    },
    aspectRatio: {
      type: String,
      default: '970 / 320',
    },
    hiddenDescription: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: null,
    },
  },
  computed: {
    aspectRatioNumber() {
      const [a, b] = this.aspectRatio.split('/').map(Number);
      return a / b;
    },
    requiredText() {
      return this.required ? '(Obrigatória)' : '(Opcional)';
    },
    formatedAspectRatio() {
      const [a, b] = this.aspectRatio.split('/');
      let finalString = `${a.replace(' ', '')}`;
      finalString += 'x';
      finalString += `${b.replace(' ', '')}`;
      return finalString;
    },
  },
  data() {
    return {
      bannerUrl: this.value,
      uploadImg: null,
      isSavingCropImage: false,
    };
  },
  methods: {
    onFileInputChange(e) {
      this.loadImageToCrop(e.target.files[0]);
    },
    drop(e) {
      this.loadImageToCrop(e.dataTransfer.files[0]);
    },

    dragLeaveOrOver() {},

    loadImageToCrop(file) {
      const reader = new FileReader();
      reader.onload = blob => {
        this.uploadImg = blob.target?.result;
      };
      reader.readAsDataURL(file);
    },
    async onSaveCropImg(file) {
      this.isSavingCropImage = true;
      const { data } = await uploadService.getAwsKeys(file.type, this.schoolId);
      const imgURL = await uploadService.upload(data.url, data.fields, file);

      this.bannerUrl = imgURL;
      this.uploadImg = null;
      this.isSavingCropImage = false;
    },
  },
  watch: {
    bannerUrl() {
      this.$emit('input', this.bannerUrl);
    },
  },
};
</script>

<style scoped lang="scss">
.banner {
  .title {
    display: flex;
    gap: 8px;
    margin-bottom: 16px;

    h4 {
      color: #3f3f3f;
      font-weight: 600;
      font-size: 14px;
    }

    span {
      font-size: 12px;
      line-height: 18px;
      color: #6f6f6f;
    }
  }

  .drop-title {
    max-width: 117px;
    text-align: center;
    font-size: 14px;
    text-decoration: underline;
  }
}
.banner-img {
  min-height: 180px;
  border-radius: 4px;
}

img {
  max-height: 180px;
  height: 100%;
}

.icon-helper {
  margin-bottom: 8px;

  .fa-image {
    font-size: 42px;
  }
}
</style>
