<template>
  <section class="container">
    <header>
      <div class="header-container">
        <div class="header-container__title">
          <h4>{{ title }}</h4>
          <span>{{ helperTitle }}</span>
        </div>
        <span class="header-container__subtitle">{{ subtitle }}</span>
      </div>
      <slot name="options"></slot>
    </header>

    <slot name="content"></slot>
  </section>
</template>

<script>
export default {
  name: 'ConfigContainer',
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
    },
    helperTitle: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  all: unset;

  display: flex;
  flex-direction: column;
  header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    .header-container {
      display: flex;
      flex-direction: column;

      &__title {
        display: flex;
        align-items: center;
        gap: 10px;

        h4 {
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          color: #262626;
        }

        span {
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
          color: #6f6f6f;
        }
      }

      &__subtitle {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        color: #6f6f6f;
      }
    }
  }
}
</style>
