<template>
  <div>
    <Container
      title="Background"
      subtitle="Adicione uma imagem ou uma cor de fundo no checkout."
      helperTitle="(Aparência do fundo da página)"
    >
      <template v-slot:options>
        <div class="tlw-flex tlw-justify-end tlw-items-center">
          <div class="tabs tlw-mb-2">
            <input
              type="radio"
              value="color"
              id="radio-background-1"
              name="tabs-background"
              v-model="selectedType"
              checked
            />
            <label class="tab" for="radio-background-1">Cor</label>
            <input type="radio" value="image" id="radio-background-2" name="tabs-background" v-model="selectedType" />
            <label class="tab" for="radio-background-2">Imagem</label>
            <span class="glider"></span>
          </div>
        </div>
      </template>
      <template v-slot:content>
        <div class="lateral-banner__content" v-if="selectedType === 'color'">
          <InputColor v-model="colorHandler" label-text="Cor do background" />
        </div>
        <div class="background-banner__content" v-else-if="selectedType === 'image'">
          <div class="background-banner__content-computer">
            <div class="background-banner__content-title">
              <h4>Computador</h4>
              <span>(Obrigatória)</span>
            </div>
            <div class="background-banner__content-computer-content">
              <InputImage
                v-model="desktopImageHandler"
                class="background-banner__computer-image"
                :schoolId="schoolId"
                hiddenDescription
                aspect-ratio="1200 / 900"
              />
              <p class="background-banner__computer-text">
                As dimensões ideias: <strong>1200x900</strong> pixels no formato JPG ou PNG e ter no máximo
                <strong>5 MB</strong> de tamanho.
              </p>
            </div>
          </div>
          <div>
            <div class="background-banner__content-title">
              <h4>Celular</h4>
              <span>(Opcional)</span>
            </div>
            <div>
              <InputImage
                v-model="mobileImageHandler"
                class="background-banner__mobile-image"
                :schoolId="schoolId"
                hiddenDescription
                aspect-ratio="360 / 900"
              />
              <p class="background-banner__mobile-text">
                As dimensões ideias: <strong>360x900</strong> pixels no formato JPG ou PNG e ter no máximo
                <strong>5 MB</strong> de tamanho.
              </p>
            </div>
          </div>
        </div>
      </template>
    </Container>
  </div>
</template>

<script>
import Container from './Container.vue';
import InputImage from '../InputImage.vue';
import InputColor from '@/components/InputColor.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'BackgroundConfig',
  components: {
    Container,
    InputImage,
    InputColor,
  },
  props: {
    schoolId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      selectedType: 'color',
    };
  },
  computed: {
    ...mapGetters('offerWizard', ['backgroundSettings']),
    colorHandler: {
      get() {
        return this.backgroundSettings.color;
      },
      set(value) {
        this.setBackgroundSettings({ color: value });
      },
    },
    desktopImageHandler: {
      get() {
        return this.backgroundSettings.image?.desktop;
      },
      set(value) {
        this.setBackgroundSettings({
          ...this.backgroundSettings,
          image: {
            ...this.backgroundSettings.image,
            desktop: value,
          },
        });
      },
    },
    mobileImageHandler: {
      get() {
        return this.backgroundSettings.image?.mobile;
      },
      set(value) {
        this.setBackgroundSettings({
          ...this.backgroundSettings,
          image: {
            ...this.backgroundSettings.image,
            mobile: value,
          },
        });
      },
    },
  },
  methods: {
    ...mapActions('offerWizard', ['setBackgroundSettings']),
    update(key, value) {
      this.setBackgroundSettings({ [key]: value });
    },
  },
  mounted() {
    const { desktop, mobile } = this.backgroundSettings.image || {};

    if (this.backgroundSettings.color) {
      this.selectedType = 'color';
    } else if (desktop || mobile) {
      this.selectedType = 'image';
    }
  },
  watch: {
    selectedType(value) {
      if (value === 'color') {
        this.update('color', '#E3E3E3');
      } else {
        const { desktop, mobile } = this.backgroundSettings.image || {};

        if (desktop || mobile) return;

        this.update('image', { desktop: null, mobile: null });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.banner-config {
  &__content {
    display: grid;
    grid-template-columns: 1fr 0.5fr;
    gap: 16px;
  }
}

.lateral-banner {
  &__content {
    display: grid;
    gap: 16px;
  }
}

input[type='radio'] {
  display: none;
}

.tabs {
  display: flex;
  position: relative;
  background-color: #e3e3e3;
  box-shadow: 0 0 1px 0 rgba(#185ee0, 0.15), 0 6px 12px 0 rgba(#185ee0, 0.15);
  padding: 2px;
  border-radius: 8px;
}

.tab {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  width: 80px;
  border-radius: 99px; // just a high number to create pill effect
  cursor: pointer;
  transition: color 0.15s ease-in;
  margin-bottom: 0;
  padding: 6px 16px;
  z-index: 2;
}

input[type='radio'] {
  &:checked {
    & + label {
      color: #262626;
      font-weight: bold;
    }
  }
}

input[id='radio-background-1'] {
  &:checked {
    & ~ .glider {
      transform: translateX(0);
    }
  }
}

input[id='radio-background-2'] {
  &:checked {
    & ~ .glider {
      transform: translateX(100%);
    }
  }
}

.glider {
  position: absolute;
  display: flex;
  height: 31.2px;
  width: 80px;
  background-color: white;
  z-index: 1;
  border-radius: 8px; // just a high number to create pill effect
  transition: 0.25s ease-out;
}

.background-banner__content {
  display: flex;
  gap: 16px;
  max-width: 806px;

  &-computer {
    width: 100%;
  }

  > div {
    display: flex;
    flex-direction: column;
    gap: 16px;
    .background-banner__content-title {
      display: flex;
      gap: 8px;
      h4 {
        color: #3f3f3f;
        font-weight: 600;
        font-size: 14px;
      }

      span {
        font-size: 12px;
        line-height: 18px;
        color: #6f6f6f;
      }
    }

    > div {
      display: flex;
      gap: 16px;
    }
  }

  .background-banner__computer-image {
    width: 100%;
  }

  .background-banner__mobile-image {
    width: 100%;
    max-width: 88px;
    min-width: 88px;

    /deep/.drop-title {
      max-width: 69px;
    }
  }

  .background-banner__computer-text,
  .background-banner__mobile-text {
    max-width: 190px;
    color: #6f6f6f;
    font-size: 12px;
    line-height: 21px;
  }

  /deep/ img {
    width: 100%;
    max-width: 293px;
    max-height: unset;
  }
}

@media screen and (max-width: 1024px) {
  /deep/.container header {
    flex-direction: column;
    align-items: start;
    gap: 24px;
  }

  .background-banner__content {
    flex-direction: column;
    &-computer {
      &-content {
        flex-direction: column;

        .background-banner__computer-text {
          max-width: unset;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .background-banner__content {
    width: 100%;
    > div {
      width: 100%;
      flex-wrap: wrap;
    }

    .background-banner__computer-text {
      max-width: unset;
    }

    .background-banner__computer-image {
      min-width: 100%;
    }
  }
}
</style>
